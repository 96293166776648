<template>
  <div class="search-classify">
    <node :ref="'node'+index" :change="handleChange" :key="index" :data="item" v-for="(item,index) in form"></node>
  </div>
</template>

<script>
  import {
    getScreen
  } from '@/api/search';

  import node from './search-screen-node';

  export default {
    components:{
      node
    },
    data() {
      return {
        active:[],
        form: [],
      };
    },
    created() {
      getScreen().then(data => {
        this.form = Array.prototype.concat(data);
      });
    },
    methods:{
      get(){
        return this.active;
      },
      handleChange(){
        var active=[];
        for (let index = 0; index < form.length; index++) {
          active.push(this.$refs["node"+index][0].get());
        }
        this.active=active;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-classify {
    width: 100%;
  }
</style>