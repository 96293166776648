<template>
  <div class="search">
    <header-user></header-user>
    <!-- <search-search ref="search"></search-search> -->
    <Search />
    <header-menu></header-menu>
    <crumbs :data="['搜索', '详情']"></crumbs>
    <div class="search-content">
      <el-table
        :data="tableData"
        :loading="loading"
        style="width: 100%"
        :span-method="activementTable"
      >
        <!-- <el-table-column
          label="产品编号"
          prop="spuNum"
          width="120px"
        ></el-table-column> -->
        <el-table-column
          label="产品名称"
          prop="name"
          width="400px"
        ></el-table-column>
        <el-table-column label="销售信息">
          <el-table-column
            :key="item.prodAttrId"
            :label="item.name"
            :prop="`attr${item.prodAttrId}`"
            v-for="item in attrList"
          />
        </el-table-column>
        <el-table-column :label="$t('common.Mark_number')" prop="skuNum"></el-table-column>
        <el-table-column label="库存" prop="inventory"></el-table-column>
        <el-table-column label="价格" prop="salePrice"></el-table-column>
        <el-table-column :label="$t('label.Controls')" width="101px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="
                openSpuDetail(
                  scope.row.storeId,
                  scope.row.spuId,
                  scope.row.skuId
                )
              "
              >立刻购买</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="kongbai"></div>
    <cart></cart>
    <foot></foot>
  </div>
</template>

<script>
import header_user from '@/components/header';
import header_menu from '@/components/home/menu';
import search_search from './search-search';
import search_crumbs from './search-crumbs';
import search_screen from './search-screen';
import foot from '@/components/foot';
import cart from '@/components/cart';
import { getProductAttr } from '@/api/productAttr.js';
import { getSkuListBySpuId } from '@/api/sku.js';
import Search from '@/components/home/search.vue';
// import { openSpuDetail } from '@/utils/pageUtils.js';

export default {
  components: {
    'header-user': header_user,
    'header-menu': header_menu,
    'search-search': search_search,
    crumbs: search_crumbs,
    screen: search_screen,
    foot: foot,
    cart: cart,
    Search,
  },
  data() {
    return {
      attrList: [],
      prodCatId: null,
      name: null,
      storeId: null,
      tableData: [],
      loading: true,
      time: 300,
      mergeSpanArr: [],
      mergeSpanArrIndex: [],
    };
  },
  created() {
    this.loading = true;
    this.prodCatId = this.$route.query.prodCatId;
    this.name = this.$route.query.name;
    this.storeId = this.$route.query.storeId;
    getProductAttr(this.prodCatId, '1').then((res) => {
      this.attrList = res.data;
    });

    getSkuListBySpuId(this.storeId, this.prodCatId, this.name).then((res) => {
      for (let index in res.data) {
        this.buildSku(res.data[index]);
      }
      this.tableData = res.data;
      this.setMergeArr(res.data);
      setTimeout(() => {
        this.loading = false;
      }, this.time);
    });
  },
  methods: {
    setMergeArr(data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.mergeSpanArr.push(1);
          this.mergeSpanArrIndex = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].name == data[i - 1].name) {
            //itemInfo代表根据哪个相同的属性去合并
            this.mergeSpanArr[this.mergeSpanArrIndex] += 1;
            this.mergeSpanArr.push(0);
          } else {
            this.mergeSpanArr.push(1);
            this.mergeSpanArrIndex = i;
          }
        }
      }
    },
    activementTable({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.mergeSpanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    buildSku(sku) {
      let saleAttrCode = JSON.parse(sku.saleAttrCode);
      for (let index in saleAttrCode) {
        let item = saleAttrCode[index];
        sku[`attr${item.prodAttrId}`] = item.attrValue;
      }
    },
    openSpuDetail(storeId, spuId) {
       const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    // openSpuDetail,
    // handleSettlementWares() {
    //   this.$router.push({
    //     name: 'settlement',
    //     params: {
    //       type: 'wares',
    //     },
    //     query: {
    //       spu: '1',
    //       sku: '1',
    //       count: '1',
    //     },
    //   });
    // },
    handleSettlementCart() {
      this.$router.push({
        name: 'settlement',
        params: {
          type: 'cart',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  background: #f5f5f5;

  &-content {
    background: #fff;
    width: 1237px;
    padding: 20px;
    margin: auto;
    min-height: 370px;
  }

  &-data {
    width: 100%;
    border: 2px solid #c5cff8;
    color: #66a3db;

    > thead {
      background: #f4f9fe;
      border: 0px;
    }

    > tbody {
    }
  }

  .button-cart,
  .button-order {
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 35px;
    line-height: 35px;
    border: 0px;

    > span {
      font-size: 12px;
    }

    &:focus {
      outline: none;
    }

    cursor: pointer;
  }

  .button {
    text-align: right;
    margin-top: 10px;

    .button-order {
      color: #184fff;
      background-image: url('../../assets/details/place_an_order.png');
      width: 106px;
    }

    .button-cart {
      margin-left: 20px;
      color: #fff;
      background-image: url('../../assets/details/place_an_cart.png');
      width: 128px;
      padding-right: 10px;

      > span {
        margin-left: 28px;
      }
    }
  }
}
</style>
